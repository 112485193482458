import React, {
  useState,
  useEffect,
  useCallback,
  useMemo,
  useRef,
} from "react";
import AWS from "aws-sdk";
import Amplify, { Auth, PubSub } from "aws-amplify";
import { AWSIoTProvider } from "@aws-amplify/pubsub";
import useStore from "./store";

export const IotConnector = ({
  username,
  awsConfig,
  shadowName,
  password,
  region,
  policy,
  mode,
  thingName,
}) => {
  const [pendingSignIn, setPendingSignIn] = useState(false);
  let isSubscribed = false;

  const desiredStateRef = useRef();

  const latestStore = useStore((s) => s);

  // is Arrtoo open?
  const cameraVisible = useMemo(() => latestStore.cameraVisible, [latestStore]);

  // IP Address of Arrtoo
  const IP = useMemo(() => latestStore.IP, [latestStore]);
  
  function timeout(ms) {
    return new Promise((resolve) => setTimeout(resolve, ms));
  }

  const sleep = useCallback(async (fn, ...args) => {
    await timeout(500);
    return fn(...args);
  }, []);

  const getIoTEndpoint = useCallback(async () => {
    // Each AWS account has a unique IoT endpoint per region. We need to retrieve this value:
    console.log("Getting IoT Endpoint...");
    const credentials = await Auth.currentCredentials();
    const iot = new AWS.Iot({
      region: region,
      credentials: Auth.essentialCredentials(credentials),
    });
    const response = await iot
      .describeEndpoint({ endpointType: "iot:Data-ATS" })
      .promise();
    const endpoint = `wss://${response.endpointAddress}/mqtt`;
    
    // alternative to secure web sockets:
    // const endpoint = `$aws/things/${username}/shadow`
    console.log(endpoint);
    return endpoint;
  }, [region]);

  const configurePubSub = useCallback(
    async (endpoint) => {
      console.log(endpoint);
      Amplify.addPluggable(
        new AWSIoTProvider({
          aws_pubsub_region: region,
          aws_pubsub_endpoint: endpoint,
        })
      );
    },
    [region]
  );

  //------------------------------------------------------------------------------
  const handleData = useCallback(
    (data) => {
      console.log("HANDLING NEW DATA: ", data);
      try {
        let desiredState = data.value.current.state.desired;
        desiredStateRef.current = desiredState;
        console.log("DESIRED STATE: ", desiredState);
        if (desiredState.IP) {
          useStore.setState({
            IP: desiredState.IP,
          });
        }
        if (desiredState.cameraVisible) {
          useStore.setState({
            cameraVisible: desiredState.cameraVisible.toLowerCase() === "true",
          });
        }
      } catch (e) {
        console.error(e);
      }
    },
    []
  );

  const attachIoTPolicyToUser = useCallback(async () => {
    if (!isSubscribed) {
      const credentials = await Auth.currentCredentials();
      const iot = new AWS.Iot({
        region: region,
        credentials: Auth.essentialCredentials(credentials),
      });
      const target = credentials.identityId;
      const response = await iot.listAttachedPolicies({ target }).promise();
      const policies = response.policies;
      const policyName = policy;
      console.log(
        `Cognito federated identity ${target} has the following attached IoT policies:\n`,
        JSON.stringify(policies, null, 2)
      );
      if (
        policies !== undefined &&
        !policies.find((policy) => policy.policyName === policyName)
      ) {
        console.log(`User is missing ${policyName} IoT policy. Attaching...`);
        await iot.attachPolicy({ policyName, target }).promise();
        console.log(`Attached ${policyName} IoT policy.`);
      } else {
        console.log(`User already has ${policyName} IoT policy attached.`);
      }
    }
  }, [isSubscribed, policy, region]);

  const getDocuments = useRef();
  const getRejected = useRef();

  const subscribeToInitialState = useCallback(async (topic) => {
    if (!isSubscribed) {
      console.log("SUBSCRIBED TO /DOCUMENTS TOPIC: ", `${topic}/documents`);
      getDocuments.current = PubSub.subscribe(`${topic}/documents`).subscribe({
        next: (data) => {
          console.log(data);
          console.log("handling documents data");
          handleData(data);
          isSubscribed = true;
          getDocuments.current.unsubscribe();
          getRejected.current.unsubscribe();
        },
        error: (error) => console.error(error),
        complete: () => console.log("Done"),
      });

      console.log("SUBSCRIBED TO /REJECTED TOPIC: ", `${topic}/rejected`);
      getRejected.current = PubSub.subscribe(`${topic}/rejected`).subscribe({
        next: (data) => console.log(data),
        error: (error) => console.error(error),
        complete: () => console.log("Done"),
      });
    }
  }, []);

  const getInitialState = useCallback(async (topic) => {
    console.log("GETTING INITIAL STATE: ", `${topic}`);
    PubSub.publish(topic, {
      msg: "",
      state: {
        desired: {
          latestClientSync: Date.now().toString(),
        },
      },
    }).then(() => {
      console.log("GETTING INITIAL STATE: SENT AN EMPTY MESSAGE");
    });
  }, []);

  const subscribeToUpdates = useCallback(
    async (topic) => {
      console.log("SUBSCRIBING TO UPDATES");
      PubSub.subscribe(topic).subscribe({
        next: (data) => handleData(data),
        error: (error) => console.error(error),
        complete: () => console.log("Done"),
      });
    },
    [handleData]
  );

  const updateState = useCallback(
    async (topic) => {
      if (cameraVisible !== null) {
        PubSub.publish(topic, {
          state: {
            desired: {
              cameraVisible: cameraVisible?.toString()?.toLowerCase(),
              // ngrokURL: https://51c6-173-56-246-126.ngrok.io
            },
          },
        });
      }
    },
    [cameraVisible]
  );

  const setup = useCallback(async () => {
    if (!isSubscribed && !pendingSignIn) {
      console.log("setting up");
      // if (isSubscribed) return;
      Amplify.configure({
        ...awsConfig,
      });

      await Auth.signIn(username, password);
      setPendingSignIn(true);

      await configurePubSub(await getIoTEndpoint());
      await attachIoTPolicyToUser();
      if (thingName) {
        await subscribeToInitialState(`$aws/things/${thingName}/shadow/get`);
        await subscribeToUpdates(
          `$aws/things/${thingName}/shadow/update/documents`
        );
        await sleep(getInitialState, [
          `$aws/things/${thingName}/shadow/update`,
        ]);
      }
    }
  }, [
    awsConfig,
    username,
    password,
    isSubscribed,
    pendingSignIn,
    configurePubSub,
    getIoTEndpoint,
    attachIoTPolicyToUser,
    subscribeToInitialState,
    thingName,
    subscribeToUpdates,
    sleep,
    getInitialState,
  ]);

  useEffect(() => {
    // run setup on first go
    console.log("CALLING SETUP");
    setup();
  }, [setup]);

  useEffect(() => {
    if ((cameraVisible !== null) && mode === "viewer") {
      (async () => {
        console.log("UPDATING");
        console.log("state is: ", cameraVisible);
        await updateState(`$aws/things/${thingName}/shadow/update`);
      })();
    }
  }, [cameraVisible, updateState, thingName, shadowName, mode]);

  return <></>;
};

export const Kiosk = ({
  username,
  awsConfig,
  shadowName,
  password,
  region,
  policy,
  mode,
  thingName,
}) => {
  if (!username) return null;
  return (
    <>
      <IotConnector
        username={username}
        awsConfig={awsConfig}
        shadowName={shadowName}
        password={password}
        region={region}
        policy={policy}
        mode={mode}
        thingName={thingName}
      />
    </>
  );
};

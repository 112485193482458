import React, { useState, useEffect } from "react";
import Protect from 'react-app-protect'
import 'react-app-protect/dist/index.css'

import "./App.css";
import useStore from "./utils/store";
import { Kiosk } from "./utils/IoTConnector";
import AdminGUI from "./components/WebRTC/AdminGUI";


function App() {
  const [username, setUsername] = useState("Arrtoo-Dean-1"); // <-- default to Dean's Arrtoo
  const [thingName, setThingName] = useState(); // <-- default to Dean's Arrtoo
  const [shadowName] = useState("Arrtoo-Dean");
  const [mode, setMode] = useState(null);

  const awsConfig =  {
    "aws_project_region": process.env.REACT_APP_AWS_PROJECT_REGION,
    "aws_cognito_identity_pool_id": process.env.REACT_APP_AWS_COGNITO_IDENTITY_POOL_ID,
    "aws_cognito_region": process.env.REACT_APP_AWS_COGNITO_REGION,
    "aws_user_pools_id": process.env.REACT_APP_AWS_USER_POOLS_ID,
    "aws_user_pools_web_client_id": process.env.REACT_APP_AWS_USER_POOLS_WEB_CLIENT_ID,
    "aws_appsync_region": process.env.REACT_APP_AWS_APPSYNC_REGION,
    "aws_appsync_authenticationType": process.env.REACT_APP_AWS_APPSYNC_AUTHENTICATION_TYPE,
  };

  useEffect(() => {
    const queryParams = new URLSearchParams(window.location.search);
    const mode = queryParams.get("mode");

    const thingNameParam = queryParams.get("thingName") || "Arrtoo"; // the name of the device
    setThingName(thingNameParam);
    useStore.setState({ thingName: thingNameParam });

    // `mode` can be 'user' or 'viewer'
    // user is for the research partipant
    // viewer is for the viewer (researcher)
    if (mode) {
      console.log(mode);
      useStore.setState({ mode: mode });
      setMode(mode);
    }
  }, []);

  return (
    <Protect sha512='2c4df8087f365cfe2fdecbea8f5ec03bcdbb5172e2037b4525f94c213499e3d8a16f83923b2b7836abc01354276c58a48d8cea7e8416ea405aec264ccc198a61'>
    <div className="App">
      <AdminGUI />
      {thingName && (
        <Kiosk
          username={"Arrtoo-Dean-1"}
          awsConfig={awsConfig}
          shadowName={shadowName}
          password={`Arrtoo-Dean-1`}
          region={"us-west-2"}
          policy={"Arrtoo_Cognito_Policy"}
          mode={"viewer"}
          thingName={thingName}
        />
      )}
    </div>
    </Protect>
  );
}

export default App;

import React, { useState, useEffect, useRef } from "react";
import { TailSpin } from "react-loader-spinner";

import useStore from "../../utils/store";
import styles from "./styles.module.css";
function AdminGUI() {
  // this is the user's device, it is controlling the stream
  const latestStore = useStore((s) => s);

  const iframeRef = useRef();

  const toggleArrtoo = async () => {
    useStore.setState({
      cameraVisible: !latestStore.cameraVisible,
    });
  };

  // local var that slightly delays showing the iFrame when camera becomes visible
  const [delayedCameraVisible, setDelayedCameraVisible] = useState(false);

  useEffect(() => {
    if (latestStore.cameraVisible && !delayedCameraVisible) {
      setTimeout(() => {
        setDelayedCameraVisible(true);
      }, 10000);
    } else {
      setDelayedCameraVisible(false);
    }
  }, [latestStore.cameraVisible]);

  useEffect(() => {
    if (delayedCameraVisible && iframeRef && iframeRef.current) {
      console.log("ADJUSTING WIDTH");
      iframeRef.current.style.height =
        iframeRef.current.contentWindow.document.documentElement.scrollHeight +
        "px";
      iframeRef.current.style.width =
        iframeRef.current.contentWindow.document.documentElement.scrollWidth +
        "px";
    }
  }, [delayedCameraVisible]);

  return (
    <div style={{ width: "100%", padding: "2rem", overFlowY: "scroll" }}>
      <h1>Arrtoo</h1>
      {latestStore.cameraVisible && (
        <div style={{ display: "flex", alignItems: "center" }}>
          Live Stream On
          <div className={styles.pulseBlob} />
        </div>
      )}
      <button onClick={toggleArrtoo}>
        Turn Camera {latestStore.cameraVisible ? "Off" : "On"}
      </button>
      <div
        style={{
          maxWidth: "100%",
          marginTop: 20,
        }}
      >
        {latestStore?.cameraVisible && !delayedCameraVisible && (
          <TailSpin color="#00BFFF" height={80} width={80} />
        )}
        {latestStore.cameraVisible &&
          delayedCameraVisible &&
          latestStore?.thingName && (
            <div className="video_wrapper">
              <iframe
                ref={iframeRef}
                frameborder="0"
                width="100%"
                height="100%"
                src={`https://${
                  latestStore.ngrokURLs[latestStore.thingName]
                }/html/cam_pic_new.php`}
                allowfullscreen
                allow="autoplay"
                title="Camera Stream"
              />
            </div>
          )}
      </div>
    </div>
  );
}

export default AdminGUI;

import create from "zustand";

export const useStore = create(() => {
  return {
    IP: null,
    thingName: null,
    cameraVisible: null,
    ngrokURL: 'arrtoo.ngrok.io',
    ngrokURLs: {
      'Arrtoo-Dean': 'arrtoo.ngrok.io',
      'Arrtoo_Blue': 'arrtoo-blue.ngrok.io',
      'Arrtoo_White': 'arrtoo-white.ngrok.io',
      'Arrtoo_Orange': 'arrtoo-orange.ngrok.io',
      'Arrtoo': 'arrtoo.ngrok.io',
    }
  };
});

export default useStore;
